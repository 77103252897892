import { EnvironmentUrls } from 'Roblox';
import { UrlConfig } from 'core-utilities';

const { apiGatewayUrl } = EnvironmentUrls;

const url = {
  getDeveloperProductsForStorePage: (universeId: string): UrlConfig => ({
    url: `${apiGatewayUrl}/developer-products/v1/universes/${universeId}/store`,
    withCredentials: true
  }),
  getGameTransactions: {
    url: `${apiGatewayUrl}/developer-products/v1/game-transactions`,
    withCredentials: true
  },
  developerProductDetailsPage: (universeId: string, productId: string): UrlConfig => ({
    url: `https://${EnvironmentUrls.domain}/developer-product/${universeId}/product/${productId}`
  })
};

export default {
  url
};
